.next-button,
.back-button {
	text-transform: uppercase;
	/* font-family: 'Roboto', sans-serif; */
	font-size: 14px;
	padding: 7px 30px 10px 30px;
	border-radius: 5px;
	border: 2px solid #0066b2;
	overflow: hidden;
	transition: all 0.5s;
	height: 40px;
	gap: 40px;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	font-weight: bold;
}

.next-button:hover,
.back-button:hover {
	cursor: pointer;
	opacity: 0.9;
	/* transform: translateY(-2px); */
}

.text1,
.text2 {
	transition: all 0.5s;
	font-family: 'Poppins', sans-serif;
}

.next-button:hover .text1,
.next-button:hover .text2,
.back-button:hover .text1,
.back-button:hover .text2 {
	transform: translateY(-63px);
}

.next-button {
	background: #0066b2;
	color: white;
}

.back-button {
	background: white;
	color: #0066b2;
}

.next-button:disabled, .back-button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
	transform: none;
	transition: none;
}

.next-button:disabled .text1,
.next-button:disabled .text2,
.back-button:disabled .text1,
.back-button:disabled .text2 {
	transform: none;
}
