.cover {
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	background: lightblue;
	color: black;
	overflow-y: auto;
	display: grid;
	place-content: center;
	display: none;
}

.content {
	width: 300px;
	background: white;
	border-radius: 1rem;
	padding: 20px 40px;
	text-align: start;
	display: flex;
	flex-direction: column;
}

@media screen and (max-width: 725px) {
	.cover {
		display: grid;
	}
}
