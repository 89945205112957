.NavigationContainer {
	background: white;
	width: 100%;
	padding-top: 30px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 20px;
}

.page {
	background: var(--background-color-one);
	display: flex;
	justify-content: center;
	min-height: 100vh;
	align-items: center;
	flex-direction: column;
}

.question-list {
	display: flex;
	flex-direction: column;
	gap: 2vh;
}

.page-title {
	font-size: 20px;
	padding-bottom: 20px;
	font-weight: bold;
}

.popup-container {
	width: 100vw;
	height: 100vh;
	position: fixed;
	background: rgba(49, 49, 49, 0.493);
	top: 0;
	left: 0;
	z-index: 1000;
}
