@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Roboto&display=swap');

:root {
	--primary-color-one: #001940;
	--primary-color-two: #0066b2;
	--background-color-one: rgb(138, 190, 206);
	--green: #32a846;
	--error-red: #d13313;
	--faded-black: rgb(40, 40, 40, 0.8);
	--bold: 600;
	--semi-bold: 500;
	--box-shadow-one: rgb(0, 0, 0, 0.3) 0px 1px 2px 2px;
}

.App {
	text-align: center;
	/* background-color: rgb(104, 135, 162); */
	background-color: var(--background-color-one);
	font-family: 'Poppins', sans-serif;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: rgb(255, 254, 254);
}

.page-content-container {
	background: white;
	padding: 50px;
	border-radius: 1rem;
}

textarea {
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	height: 150px;
}
